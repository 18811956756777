<template>
  <v-card class="mb-5">
    <v-card-title> Create a New Workspace </v-card-title>
    <v-card-text>Use this form to create a new Workspace.</v-card-text>
    <v-card-text>
      <v-alert v-if="errorMsg" type="error">{{ errorMsg }}</v-alert>
      <v-form ref="form" @submit.prevent="onSubmitForm">
        <v-text-field v-model="organisationName" label="Workspace Name" :rules="organisationNameRules"></v-text-field>
        <v-autocomplete
          v-if="!groupUuid"
          v-model="organisationGroup"
          label="Organisation"
          clearable
          :items="groupList"
          item-value="groupUuid"
          item-text="groupName"
          no-data-text="No results found"
          :rules="organisationGroupRules"
        ></v-autocomplete>
        <v-btn type="submit" color="success" class="mr-2 mt-2" :loading="isSubmitting">
          <v-icon>{{ icons.mdiCheckCircle }}</v-icon>
          &nbsp; Submit
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import icons from '@/utils/icons'
import EventBus from '@/classes/EventBus'
import Groups from '@/classes/Groups'
import UploadsApi from '@/classes/UploadsApi'
import requiredRule from '@/rules/requiredRule'

export default {
  props: {
    groupUuid: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      organisationName: '',
      organisationNameRules: [requiredRule],
      organisationGroup: '',
      organisationGroupRules: [requiredRule],

      // isValid: false,
      // loading: false,
      // errorDialog: false,

      icons,

      isSubmitting: false,
      Groups,
      groupList: [{ groupName: 'Loading...' }],
      errorMsg: null,
    }
  },
  mounted() {
    Groups.onLoaded(groups => {
      this.groupList = groups.filter(group => !group?.adminWorkspaceOnly)
    })
  },
  methods: {
    async onSubmitForm() {
      const valid = await this.$refs.form.validate()
      if (!valid) {
        return
      }

      this.errorMsg = null
      this.isSubmitting = true
      const organisation = {
        organisationUuid: uuidv4(),
        organisationName: this.organisationName,
        applicationId: process.env.VUE_APP_DEFAULT_APPLICATION_ID,
        groupUuid: this.groupUuid || this.organisationGroup,
      }
      const response = await UploadsApi.adminPersistOrganisation(organisation)
      if (!response?.success) {
        this.errorMsg = response?.message || 'An unexpected error occurred'

        // todo: ui error msg
        // todo: render errors if there are any https://stackoverflow.com/questions/48613643/server-side-form-validation-with-vue-js-and-vuetify
        // if (response.errors?.organisationName) {
        //   console.log('submit response.errors?.organisationName', response.errors?.organisationName)
        // }
      } else {
        // no errors - reset form
        this.errorMsg = null
        this.$refs.form.reset()
        EventBus.$emit('organisationCreated', organisation)
      }
      this.isSubmitting = false
    },
  },
}
</script>
