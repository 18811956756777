<template>
  <v-card class="mb-5">
    <v-dialog v-model="addNewModal" width="80%">
      <AdminCreateOrganisation />
    </v-dialog>
    <v-dialog v-model="editModal" width="80%" max-width="850" @close="editItem = null">
      <AdminEditOrganisation :entity="editItem" />
    </v-dialog>

    <ResultMsg ref="resultMsg"></ResultMsg>

    <v-sheet class="d-flex mb-0">
      <v-sheet class="mr-auto">
        <v-card-title> Workspaces List </v-card-title>
        <v-card-text> Here is the list of all active workspaces that you manage. </v-card-text>
      </v-sheet>

      <v-sheet class="pa-4">
        <v-btn v-if="User.isSuperAdmin || addNewEnabled" color="primary" @click="addNewModal = true">
          <v-icon>{{ icons.mdiPlusCircle }}</v-icon>
          &nbsp; NEW WORKSPACE
        </v-btn>
      </v-sheet>
    </v-sheet>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="organisationList"
      item-key="organisationUuid"
      class="table-rounded"
      show-select
      :items-per-page="30"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 30, 50, 100, -1],
      }"
    >
      <template #[`item.organisationName`]="{ item }">
        <router-link :to="{ name: 'admin-workspace', params: { organisationUuid: item.organisationUuid } }">
          {{ item.organisationName }}
        </router-link>
      </template>
      <template #[`item.groupName`]="{ item }">
        <router-link :to="{ name: 'admin-group', params: { groupUuid: item.groupUuid } }">
          {{ item.groupName }}
        </router-link>
      </template>
      <template #[`item.actions`]="{ item }">
        <div class="mt-1 row-actions">
          <v-btn color="primary" class="mb-1 mr-1 pa-0" title="Edit Workspace" @click="clickEdit(item)">
            <v-icon>{{ icons.mdiPencil }}</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import icons from '@/utils/icons'
import escapeRegExp from '@/utils/escapeRegExp'
import onUserLoaded from '@/utils/onUserLoaded'
import EventBus from '@/classes/EventBus'
import Groups from '@/classes/Groups'
import Organisations from '@/classes/Organisations'
import User from '@/classes/User'
import ResultMsg from '@/views/utils/ResultMsg.vue'
import AdminCreateOrganisation from '@/views/admin/AdminCreateOrganisation.vue'
import AdminEditOrganisation from '@/views/admin/AdminEditOrganisation.vue'

export default {
  components: {
    ResultMsg,
    AdminCreateOrganisation,
    AdminEditOrganisation,
  },
  data() {
    return {
      User,
      organisationList: [],
      selected: [],
      loading: true,
      headers: [
        { text: 'ID', value: 'organisationUuid' },
        { text: 'NAME', value: 'organisationName' },
        { text: 'ORG', value: 'groupName' },
        { text: 'ACTIONS', value: 'actions', sortable: false },
      ],
      icons,
      addNewEnabled: false,
      addNewModal: false,
      editModal: false,
      editItem: null,
    }
  },
  mounted() {
    onUserLoaded(this.initialise)
    Organisations.onLoaded(() => {
      this.loadOrgList()
      this.$forceUpdate()
      this.loading = false
    })
    Groups.onLoaded(groups => {
      this.addNewEnabled = groups.some(group => !group?.adminWorkspaceOnly)
      this.loadOrgList()
      this.$forceUpdate()
    })
    EventBus.$on('organisationCreated', () => {
      this.loading = true
      this.addNewModal = false
    })
    EventBus.$on('organisationEdited', () => {
      this.loading = true
      this.editModal = false
      this.editItem = null
    })
    EventBus.$on('adminOrganisationListFilter', filterEvent => {
      this.filterEvent = filterEvent
      this.applyFilters()
    })
  },
  methods: {
    initialise() {
      // nothing to do?
    },
    loadOrgList() {
      this.organisationListFull = Organisations.getAll().map(org => ({
        ...org,
        groupName: Groups.getNameById(org.groupUuid),
      }))
      this.applyFilters()
    },
    applyFilters() {
      this.organisationList = this.organisationListFull.filter(organisation => {
        if (this.filterEvent?.organisationSearch) {
          const searchRegex = new RegExp(escapeRegExp(this.filterEvent.organisationSearch), 'i')
          if (organisation?.organisationName.match(searchRegex)) {
            return true
          }
          if (organisation?.organisationUuid.match(searchRegex)) {
            return true
          }

          return false
        }

        return true
      })
    },
    clickEdit(organisation) {
      this.editItem = organisation
      this.editModal = true
    },
  },
}
</script>

<style lang="scss" scoped>
/** todo: refactor this **/
.row-actions {
  margin-right: -4px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.row-actions .v-btn {
  min-width: 38px;
}
</style>
