import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDialog,{attrs:{"width":"unset"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c(VCard,{staticClass:"pa-2"},[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._v(_vm._s(_vm.message))]),_c(VCardActions,[_c(VBtn,{attrs:{"color":_vm.color},on:{"click":_vm.closeDialog}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }