<template>
  <v-dialog v-model="active" width="unset">
    <v-card class="pa-2">
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ message }}</v-card-text>
      <v-card-actions>
        <v-btn :color="color" @click="closeDialog">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Result',
    },
    message: {
      type: String,
      default: 'An unexpected error occured',
    },
    color: {
      type: String,
      default: 'success',
    },
  },
  data() {
    return {
      active: false,
    }
  },
  methods: {
    openDialog() {
      this.active = true
    },
    closeDialog() {
      this.active = false
    },
    responseMsg({ success = false, summary, message }) {
      this.color = success ? 'success' : 'error'
      this.title = summary
      if (!summary) {
        this.title = success ? 'Success' : 'Error'
      }
      this.message = message
      if (!message) {
        this.message = success ? 'The operation was successful' : 'The operation failed'
      }
      this.openDialog()
    },
  },
}
</script>
